<template>
	<el-dialog title="选择地址" :visible.sync="chooseAddressVisible" width="576px" center append-to-body :close-on-click-modal="false">
		<Map
			@newPosition="newPosition"
			ref="map"
			:mapHeight="250"
			:isShowSearch="true"
			:isShowText="true"
			:centerPoint="center"
			:addressText="addressText"
		></Map>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="chooseAddressVisible = false">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Map: () => import('@/components/Map/index.vue'),
	},
	props: ['centerPoint'],
	data() {
		return {
			chooseAddressVisible: false,
			center: [],
			addressText: '',
		};
	},
	mounted() {
		let latitude = JSON.parse(sessionStorage.getItem('userInfo')).latitude;
		let longitude = JSON.parse(sessionStorage.getItem('userInfo')).longitude;
		if (latitude && longitude) {
			this.center = [longitude, latitude];
		} else {
			this.center = [0, 0];
		}
	},
	methods: {
		newPosition(addressInfo) {
			this.$emit('changeAddress', addressInfo);
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .amap-page-container {
	.el-vue-search-box-container {
		box-shadow: none;
	}
	.search-box-wrapper {
		input {
			margin-right: 16px;
			border: 1px solid #dcdfe6;
			height: 32px;
			line-height: 32px;
			box-sizing: border-box;
			border-radius: 4px;
		}
		.search-btn {
			border: 1px solid #1db9b1;
			background-color: #1db9b1;
			color: #fff;
			border-radius: 4px;
			width: 64px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			box-sizing: border-box;
		}
	}
}
</style>